import { ScriptProps } from 'next/script'
import { trackItemClicked } from 'components/tracking'

export const getZendeskScriptProps = (
  areFunctionalCookiesAllowed: boolean,
  locale?: string
): ScriptProps => {
  const zendeskScriptKey = 'f669a824-5592-4bd4-a8b3-ebafe5198c13'

  return {
    src: `https://static.zdassets.com/ekr/snippet.js?key=${zendeskScriptKey}`,
    id: 'ze-snippet',
    strategy: 'lazyOnload',
    onLoad: () => {
      // @ts-expect-error - Zendesk scripts
      window.zE(() => {
        // @ts-expect-error - Zendesk scripts
        window.zE('messenger:set', 'cookies', areFunctionalCookiesAllowed)
        // @ts-expect-error - Zendesk scripts
        window.zE('messenger:set', 'locale', locale)
        // @ts-expect-error - Zendesk scripts
        window.zE('messenger:set', 'conversationTags', ['help-center-zd-bot'])

        // Track widget open
        // @ts-expect-error - Zendesk scripts
        window.zE('messenger:on', 'open', function () {
          trackItemClicked({
            item: 'chat_support',
            item_type: 'widget',
            location: 'help_center',
            action: 'open',
          })
        })
      })
    },
  }
}
