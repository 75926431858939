import { TARGET_PROP_TYPE } from 'constants/prop-types'

import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledLinkCard,
  StyledLinkCardHeadline,
  StyledLinkCardHeadlineIcon,
} from './link-card.styles'

const LinkCard = ({
  href,
  title,
  description,
  icon,
  target,
  onClick,
  ...rest
}) => {
  return (
    <StyledLinkCard href={href} target={target} onClick={onClick} {...rest}>
      <StyledLinkCardHeadline>
        {icon && (
          <StyledLinkCardHeadlineIcon>{icon}</StyledLinkCardHeadlineIcon>
        )}
        {title}
      </StyledLinkCardHeadline>
      {description}
    </StyledLinkCard>
  )
}
LinkCard.displayName = 'LinkCard'

LinkCard.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
  target: TARGET_PROP_TYPE,
  onClick: PropTypes.func,
}

LinkCard.defaultProps = {
  description: null,
  icon: null,
  target: '_self',
  onClick: () => {},
}

export default LinkCard
