import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import styled, { css, keyframes } from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import { getTooltipTop, getElementBoundingRectInPixels } from './helpers'

const ARROW_HEIGHT = '6px'
const ARROW_WIDTH = '4px'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const fadeInAnimation = css`
  animation: ${fadeIn} ${({ transitionDuration }) => transitionDuration}ms
    normal forwards;
`

const fadeOutAnimation = css`
  animation: ${fadeOut} ${({ transitionDuration }) => transitionDuration}ms
    normal forwards;
`

// UI Components
export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`

export const TooltipContent = styled.div`
  z-index: 10;
  max-width: ${({ maxWidth }) => (!!maxWidth ? `${maxWidth}px` : 'inherit')};
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: ${({ labelRef }) => getTooltipTop(labelRef)};
  left: ${({ labelRef }) => getElementBoundingRectInPixels(labelRef)};
  width: ${({ width }) => (!!width ? `${width}px;` : '100%')};

  ${({ left }) => (!!left ? `left: ${left}px;` : '')};

  &.centered {
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100% - 32px); /* 100% Screen - 16px padding on each side */
  }

  &.animate-in {
    ${fadeInAnimation};
  }

  &.animate-out {
    ${fadeOutAnimation};
  }
`

export const TooltipContentBox = styled.div`
  background-color: ${NeutralColors.Ink};
  border-radius: ${BORDER_RADIUS.small};
  color: ${NeutralColors.White};
  display: block;
  font-size: ${fontSize.xSmall};
  max-width: ${({ maxWidth }) => (!!maxWidth ? `${maxWidth}px` : 'inherit')};
  padding: 8px 12px;
  position: absolute;
  top: ${ARROW_HEIGHT};
  user-select: none;
  word-break: break-word;
`

export const Label = styled.div`
  user-select: none;
  width: 100%;

  &::after {
    content: '';
    opacity: 0;
    border-color: transparent transparent ${NeutralColors.Ink} transparent;
    border-style: solid;
    border-width: 0 ${ARROW_WIDTH} ${ARROW_HEIGHT} ${ARROW_WIDTH};
    height: ${ARROW_HEIGHT};
    position: absolute;
    bottom: -${ARROW_HEIGHT};
    left: ${({ labelWidth }) => labelWidth / 2 || 0}px;
    width: ${ARROW_WIDTH};

    ${({ isArrowVisible }) =>
      isArrowVisible ? fadeInAnimation : fadeOutAnimation};
  }
`
