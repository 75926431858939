import styled from 'styled-components'

import {
  StyledHeadline,
  BodyCss,
  FONT_WEIGHT_BOLDER,
} from '../typography.styles'

export const StyledQuestionHeadline = styled(StyledHeadline)`
  margin-bottom: 24px;
`

export const StyledQuestionsSection = styled.div`
  padding: 96px;
  text-align: center;

  ${BodyCss};
`

export const StyledQuestionsParagraph = styled.div`
  margin-bottom: 0;

  ${BodyCss};

  a {
    text-decoration: underline;
    font-weight: ${FONT_WEIGHT_BOLDER};
  }
`
