export const VISIBLE = 'animate-in'
export const HIDDEN = 'animate-out'
export const CENTERED = 'centered'

const getAnimationStatusClassName = (isOpen, exiting, showAlways) => {
  if (!!exiting) {
    return HIDDEN
  }
  if (isOpen || showAlways) {
    return VISIBLE
  }
  return null
}

const getCenteredClassName = (contentBoxWidth, labelRef) =>
  contentBoxWidth + labelRef?.current?.getBoundingClientRect().x >
  window.document.body.offsetWidth
    ? CENTERED
    : null

export const getClassNames = ({
  contentBoxWidth,
  labelRef,
  isOpen,
  exiting,
  showAlways,
}) =>
  [
    getCenteredClassName(contentBoxWidth, labelRef),
    getAnimationStatusClassName(isOpen, exiting, showAlways),
  ]
    .filter(className => !!className)
    .join(' ')

export const getElementBoundingRect = (el, prop = 'x') => {
  if (el?.nodeType !== Node.ELEMENT_NODE) {
    return null
  }
  return el?.getBoundingClientRect()[prop] || null
}

export const getElementBoundingRectInPixels = (el, prop = 'x') => {
  if (!getElementBoundingRect(el, prop)) {
    return ''
  }
  return `${getElementBoundingRect(el, prop)}px`
}

export const getTooltipTop = label => {
  if (label?.nodeType !== Node.ELEMENT_NODE) {
    return 'auto'
  }

  const distance =
    window?.pageYOffset +
    getElementBoundingRect(label, 'y') +
    getElementBoundingRect(label, 'height')

  return typeof distance === 'number' ? `${distance}px` : 'auto'
}

export const shouldShowTooltip = (isOpen, showAlways, labelWidth) =>
  (showAlways && typeof window !== 'undefined') || (isOpen && !!labelWidth)
