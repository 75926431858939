import React, { memo, useState, useRef, useEffect, useCallback } from 'react'
import { any, bool, element, func, number } from 'prop-types'
import { createPortal } from 'react-dom'
import throttle from 'lodash.throttle'

import {
  Wrapper,
  TooltipContent,
  TooltipContentBox,
  Label,
} from './tooltip.styles'
import { getClassNames, shouldShowTooltip, VISIBLE } from './helpers'

const Tooltip = ({
  showAlways,
  content,
  children,
  maxWidth,
  width,
  fullWidth,
  transitionDuration,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false || showAlways)
  const [exiting, setExiting] = useState(false)
  const [labelWidth, setLabelWidth] = useState(0)
  const [contentBoxWidth, setContentBoxWidth] = useState(0)
  const [screenWidth, setScreenWidth] = useState(0)

  const labelRef = useRef()
  const contentBoxRef = useRef()

  useEffect(() => {
    const resizeCallback = throttle(() => {
      setScreenWidth(window.innerWidth)
    }, 200)
    window.addEventListener('resize', resizeCallback)
    setScreenWidth(window.innerWidth)
    setLabelWidth(labelRef?.current?.firstChild.offsetWidth)
    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  }, [screenWidth, contentBoxWidth])

  const handleMouseEnter = () => {
    if (showAlways) {
      return
    }
    setIsOpen(true)
    setExiting(false)
    onOpen()
  }
  const handleMouseLeave = () => {
    if (showAlways) {
      return
    }
    setExiting(true)
  }
  const handleTouch = () => {
    if (showAlways) {
      return
    }
    setIsOpen(() => !isOpen)
    if (isOpen) {
      onOpen()
    }
  }

  const handleAnimationEnd = event => {
    if (event.target.classList.contains(VISIBLE)) {
      return
    }
    setIsOpen(false)
    setExiting(false)
  }

  const getTooltip = useCallback(() => {
    if (!!contentBoxRef?.current && !contentBoxWidth) {
      setContentBoxWidth(contentBoxRef?.current?.offsetWidth)
      return null
    }

    const getClassNamesArgs = {
      contentBoxWidth,
      labelRef,
      isOpen,
      exiting,
      showAlways,
    }

    return createPortal(
      <TooltipContent
        role='tooltip'
        className={getClassNames(getClassNamesArgs)}
        width={width}
        maxWidth={maxWidth}
        onAnimationEnd={handleAnimationEnd}
        transitionDuration={transitionDuration}
        labelRef={labelRef?.current}
        screenWidth={screenWidth}
      >
        <TooltipContentBox ref={contentBoxRef} maxWidth={maxWidth}>
          {content}
        </TooltipContentBox>
      </TooltipContent>,
      document.body
    )
  }, [
    transitionDuration,
    width,
    maxWidth,
    content,
    contentBoxWidth,
    contentBoxRef,
    exiting,
    isOpen,
    screenWidth,
    showAlways,
  ])

  return (
    <Wrapper fullWidth={fullWidth}>
      <Label
        data-testid='tooltip-label'
        ref={labelRef}
        labelWidth={labelWidth}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchEnd={handleTouch}
        isArrowVisible={(isOpen && !exiting) || showAlways}
        transitionDuration={transitionDuration}
      >
        {children}
        {shouldShowTooltip(isOpen, showAlways, labelWidth) &&
          getTooltip(contentBoxWidth)}
      </Label>
    </Wrapper>
  )
}

Tooltip.displayName = 'Tooltip'
Tooltip.defaultProps = {
  showAlways: false,
  maxWidth: null,
  width: null,
  fullWidth: false,
  transitionDuration: 175,
  onOpen: () => null,
}

Tooltip.propTypes = {
  showAlways: bool,
  content: any.isRequired,
  children: element.isRequired,
  maxWidth: number,
  width: number,
  fullWidth: bool,
  transitionDuration: number,
  onOpen: func,
}

export default memo(Tooltip)
