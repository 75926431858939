import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { BOX_SHADOWS } from '@typeform/ginger/dist/constants/shadows'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled, { css } from 'styled-components'

const BoldBodyCss = css`
  font-feature-settings: 'ss02' on;
  font-style: normal;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
`

const LinkCardBodyCss = css`
  background: ${NeutralColors.White};
  color: ${NeutralColors.Grey700};

  ${BoldBodyCss};

  ${mediaQueries.md`
    font-size: ${fontSize.x2Small};
    line-height: ${lineHeight.large};
  `};
`

export const StyledLinkCard = styled.a`
  box-shadow: ${BOX_SHADOWS.small};
  border-radius: ${BORDER_RADIUS.medium};
  display: block;
  padding: 24px;
  grid-column: span 12 / auto;

  ${mediaQueries.md`
    padding: 16px;
    grid-column: span 4 / auto;
  `};

  ${LinkCardBodyCss};
`

const LinkCardHeadlineCss = css`
  font-weight: ${fontWeight.medium};
  color: ${NeutralColors.Ink};
  display: flex;
  margin-bottom: 8px;

  ${mediaQueries.sm`
    font-size: ${fontSize.medium};
    line-height: ${lineHeight.medium};
  `};

  ${mediaQueries.md`
    font-size: ${fontSize.large};
    line-height: ${lineHeight.small};
  `};

  ${mediaQueries.lg`
    ${BoldBodyCss};
  `};
`

export const StyledLinkCardHeadline = styled.h3`
  ${LinkCardHeadlineCss};
`

export const StyledLinkCardHeadlineIcon = styled.div`
  margin-right: 8px;
`
