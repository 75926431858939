import styled from 'styled-components'
import { Headline } from '@typeform/ginger/dist/components/typography'
import { HEADER_HEIGHT } from '@typeform/ginger/dist/lib/constants'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'

export const StyledHeader = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  ${mediaQueries.sm`
    padding: 165px 85px;
  `}
`

export const StyledInnerHeader = styled.div`
  grid-column: 1 / span 12;
  max-width: 820px;
  margin: 0 auto;
`

export const StyledHeadline = styled(Headline)`
  font-weight: ${fontWeight.regular};
  font-size: 50px;
  line-height: ${lineHeight.xSmall};
  letter-spacing: -0.01em;
  font-feature-settings: 'ss02' on;
  text-align: center;
  margin-bottom: 31px;

  ${mediaQueries.sm`
    font-size: ${fontSize.x4Large};
  `}
`
