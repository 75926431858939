import styled from 'styled-components'
import { Card } from 'components/help/video-link-card/video-link-card.styles'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'

export const Container = styled.div`
  ${mediaQueries.sm`
    padding: ${spacing(0, 10)};
  `};

  ${Card} {
    grid-column: 1 / -1;

    ${mediaQueries.md`
      grid-column: span 4;
    `};
  }
`
