import Script from 'next/script'
import { useRouter } from 'next/router'
import useCookieSettings from 'hooks/use-cookie-settings'
import { getZendeskScriptProps } from 'lib/zendesk'

export const ZendeskWidget = () => {
  const { locale } = useRouter()
  const { areFunctionalCookiesAllowed } = useCookieSettings()

  return (
    <>
      {areFunctionalCookiesAllowed ? (
        <Script
          {...getZendeskScriptProps(areFunctionalCookiesAllowed, locale)}
          data-testid='zendesk-script'
        />
      ) : (
        <></>
      )}
    </>
  )
}
