import {
  LINK_CARD_MODULE,
  VIDEO_LINK_CARD_MODULE,
} from 'constants/content-types'

import PropTypes from 'prop-types'
import VideoLinkCard from 'components/help/video-link-card'
import LinkCard from 'components/help/link-card'
import { useLocaleContext } from 'components/locale-context'
import { helpHubTrackClickSectionButton } from 'components/help/hub/tracking'
import { CARD_ICONS } from 'components/help/hub/constants'
import { CTA_PROP_TYPES } from 'components/help/hub/constants/prop-types'
import ContentGridModule from 'components/modules/content-grid-module'
import { useMemo } from 'react'

import { Container } from './card-grid-section.styles'

const getCardGridCtaClickHandler = (cta, tracking) => () =>
  helpHubTrackClickSectionButton({
    ...tracking,
    link_url: cta.link,
    label: tracking.label || cta.text,
  })

const getLinkCardClickHandler = (link, tracking) => () =>
  helpHubTrackClickSectionButton({
    ...tracking,
    link_url: link.link,
    label: link.title,
  })

export const CardGridSection = ({ title, subtitle, cta, items, tracking }) => {
  const { locale } = useLocaleContext()

  const cardItems = items.map(link => {
    switch (link.contentType) {
      case LINK_CARD_MODULE:
        return (
          <LinkCard
            key={link.id}
            href={link.link}
            title={link.title}
            description={link.description}
            icon={CARD_ICONS[link.type]}
            target={link.target}
            onClick={getLinkCardClickHandler(link, tracking)}
          />
        )
      case VIDEO_LINK_CARD_MODULE:
        return (
          <VideoLinkCard
            key={link.id}
            title={link.title}
            duration={link.duration}
            image={link.image}
            locale={locale}
            href={link.link}
            target={link.target}
            onClick={getLinkCardClickHandler(link, tracking)}
          />
        )
      default:
        return null
    }
  })

  const actionBar = useMemo(() => {
    if (!cta) return undefined

    const { text, url } = cta

    return {
      primaryLink: {
        ...cta,
        'label': text,
        'href': url,
        'onClick': getCardGridCtaClickHandler(cta, tracking),
        'data-testid': 'sectionCta',
      },
    }
  }, [cta, tracking])

  return (
    <Container>
      <ContentGridModule
        heading={title}
        subheading={subtitle}
        actionBar={actionBar}
      >
        {cardItems}
      </ContentGridModule>
    </Container>
  )
}

CardGridSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  cta: CTA_PROP_TYPES,
  items: PropTypes.arrayOf(PropTypes.object),
  tracking: PropTypes.shape({
    item: PropTypes.string,
    item_name: PropTypes.string,
    location: PropTypes.string,
    typeform_property: PropTypes.string,
  }),
}

CardGridSection.defaultProps = {
  subtitle: null,
  cta: null,
  items: [],
  tracking: {},
}
