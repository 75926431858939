import { NAMESPACES } from 'constants/locales'
import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import { useCallback } from 'react'
import { useLocaleContext } from 'components/locale-context'
import { routes } from 'utils'
import useTranslation from 'next-translate/useTranslation'
import {
  helpHubTrackClickCommunityLink,
  helpHubTrackClickContactSupportLink,
  helpHubTrackClickTooltipLogin,
} from 'components/help/hub/tracking'

import Tooltip from '../../../modified-kitt/tooltip'

import {
  StyledQuestionsSection,
  StyledQuestionHeadline,
  StyledQuestionsParagraph,
} from './questions-section.styles'

export const QuestionsSection = () => {
  const { locale } = useLocaleContext()
  const { t } = useTranslation(NAMESPACES.HELP)

  const handleClickCommunityLink = useCallback(
    () =>
      helpHubTrackClickCommunityLink({
        link_url: t('help-hub.questions-section.ask-community.link', {
          TYPEFORM_ROOT_DOMAIN,
        }),
        label: t('help-hub.questions-section.ask-community.text'),
      }),
    [t]
  )

  const handleClickContactLink = useCallback(
    () =>
      helpHubTrackClickContactSupportLink({
        link_url: routes(locale).help.contact.path,
        label: t('help-hub.questions-section.contact-us'),
      }),
    [t, locale]
  )

  const handleClickLogin = useCallback(
    () =>
      helpHubTrackClickTooltipLogin({
        link_url: t('help-hub.questions-section.login.link', {
          TYPEFORM_ROOT_DOMAIN,
        }),
        label: t('help-hub.questions-section.login.text'),
      }),
    [t]
  )

  return (
    <StyledQuestionsSection>
      <StyledQuestionHeadline>
        {t('help-hub.questions-section.still-got-questions')}
      </StyledQuestionHeadline>
      <StyledQuestionsParagraph>
        {t('help-hub.questions-section.feel-free')}
        <a
          href={routes(locale).help.contact.path}
          data-testid='contact-us'
          onClick={handleClickContactLink}
        >
          {t('help-hub.questions-section.contact-us')}
        </a>{' '}
        {t('help-hub.questions-section.or')}&nbsp;
        <a
          href={t('help-hub.questions-section.ask-community.link', {
            TYPEFORM_ROOT_DOMAIN,
          })}
          target='_blank'
          rel='noreferrer'
          data-testid='ask-community'
          onClick={handleClickCommunityLink}
        >
          {t('help-hub.questions-section.ask-community.text')}
        </a>
        .
      </StyledQuestionsParagraph>
      <StyledQuestionsParagraph>
        {t('help-hub.questions-section.business-premium')}&nbsp;
        <Tooltip
          content={t('help-hub.questions-section.login.tooltip')}
          width={524}
        >
          <a
            data-testid='login'
            href={t('help-hub.questions-section.login.link', {
              TYPEFORM_ROOT_DOMAIN,
            })}
            onClick={handleClickLogin}
          >
            {t('help-hub.questions-section.login.text')}
          </a>
        </Tooltip>
        &nbsp;
        {t('help-hub.questions-section.use-live-chat')}
      </StyledQuestionsParagraph>
    </StyledQuestionsSection>
  )
}

QuestionsSection.defaultProps = {}

QuestionsSection.propTypes = {}
